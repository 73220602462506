<template lang="pug">
include ../../../../configs/template
div.seafarerInfoList.row.text-left.pt-8
  div(v-if="sailorDocument.nostrification_document").col-12
    +switch('isOriginal','originalDocument','nostrificationDocument','toShowOriginalOrNostrificationDocument')(@click="isOriginal = !isOriginal")
    v-divider

  div.col-sm-12.col-md-4
    +data-info('registrationNumber', 'sailorDocument.registry_number')
  div.col-sm-12.col-md-4
    +data-info('serial', 'sailorDocument.serial')
  div.col-sm-12.col-md-4
    +data-info('number', 'sailorDocument.number_document')
  Ukrainian(:sailorDocument="sailorDocument" v-if="!isOriginal")
  Original(:sailorDocument="sailorOriginalDocument" v-else)
  div.col-sm-12
    +data-info('duplicate', 'sailorDocument.is_duplicate ? $t("yes") : $t("no")')
  div(v-if="sailorDocument.is_international").col-sm-12
    +data-info('international', 'sailorDocument.is_international ? $t("yes") : $t("no")')
  div.col-sm-12.col-md-4
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_issue_document)')
  div(v-if="[TYPE_DOCUMENT_NZ.DIPLOMA_OF_HIGHER_EDUCATION, TYPE_DOCUMENT_NZ.DIPLOMA_OF_QUALIFIED_WORKER].includes(sailorDocument.type_document) && sailorDocument.date_end_educ").col-sm-12.col-md-4
    +data-info('graduationYear', 'sailorDocument.date_end_educ.split("-")[0]')
  div(v-if="sailorDocument.expired_date").col-sm-12.col-md-4
    +data-info('dateTermination', 'getDateFormat(sailorDocument.expired_date)')
  div.col-sm-12.col-md-6(v-if="sailorDocument.date_start_educ")
    +data-info('date_start_educ', 'getDateFormat(sailorDocument.date_start_educ)')
  div.col-sm-12.col-md-6(v-if="sailorDocument.date_end_educ && [TYPE_DOCUMENT_NZ.CERTIFICATE_OF_IMPROVING_QUALIFICATION, TYPE_DOCUMENT_NZ.CERTIFICATE_OF_AWARDING_IMPROVING_OF_TRADE_QUALIFICATION].includes(sailorDocument.type_document)")
    +data-info('date_end_educ', 'getDateFormat(sailorDocument.date_end_educ)')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'sailorDocument.created_by.name')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'sailorDocument.created_by.date')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verifier', 'sailorDocument.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'sailorDocument.verificated_by.date')
  div.col-sm-12
    +data-info('status', 'statusById(sailorDocument.status_document)[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div(v-if="sailorDocument.comments?.length").col-12
    v-divider
  div(v-if="sailorDocument.comments?.length").col-sm-12
    div.w-100.p-0.m-0
      h5.p-0.m-0 {{$t('rejectionReasonDocument')}}:
      v-divider
      div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonDocument','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreateDocument', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== sailorDocument.comments.length-1")
  div(v-if="false").col-12
    v-divider
  div(v-if="false").d-flex.flex-wrap.pb-4
    div.py-3.mr-4
      v-btn(v-if="sailorDocument.nostrification_document" color="primary" @click="saveDocument('translationDocument', 'wotermark')" :loading="isLoading.translationDocument.wotermark").wordBreak {{$t('saveSignDocumetTranslate')}}
    div.py-3.mr-4
      v-btn(v-if="sailorDocument.nostrification_document" color="primary" @click="saveDocument('translationDocument', 'archive')" :loading="isLoading.translationDocument.archive").wordBreak {{$t('saveSignDocumetTranslateArchive')}}
    div.py-3.mr-4
      v-btn(v-if="sailorDocument.nostrification_document" color="primary" @click="saveDocument('monDocument', 'wotermark')" :loading="isLoading.monDocument.wotermark").wordBreak {{$t('saveSignDocumetMon')}}
    div.py-3.mr-4
      v-btn(v-if="sailorDocument.nostrification_document" color="primary" @click="saveDocument('monDocument', 'archive')" :loading="isLoading.monDocument.archive").wordBreak {{$t('saveSignDocumetMonArchive')}}
</template>

<script>
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { TYPE_DOCUMENT_NZ } from '@/configs/constants'
export default {
  components: {
    Ukrainian: () => import('./Ukrainian.vue'),
    Original: () => import('./Original.vue')
  },
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) },
    sailorOriginalDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      TYPE_DOCUMENT_NZ,
      getDateFormat,
      getStatus,
      checkAccess,
      rejectStatusId: 13,
      isOriginal: false,
      isLoading: {
        translationDocument: {
          archive: false,
          wotermark: false
        },
        monDocument: {
          archive: false,
          wotermark: false
        }
      }
    }
  },
  computed: {
    ...mapGetters(['educationTypeDocumentByID', 'extentByID', 'institutionByID', 'educationProfessionByID',
      'specializationByID', 'qualificationLevelByID', 'statusById', 'rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    }
  },
  methods: {
    ...mapActions(['saveDocumentNostrification']),
    async saveDocument (typeDocument, typeFile) {
      this.isLoading[typeDocument][typeFile] = true
      const { id } = this.sailorOriginalDocument
      let contentType = typeFile === 'archive' ? 'application/zip' : 'application/pdf'
      let fileName = typeDocument === 'mesDocument' ? `Nostrification_document_${this.sailorOriginalDocument.number_document}` : `Translation_document_${this.sailorOriginalDocument.number_document}`
      await this.saveDocumentNostrification({ id, typeDocument, typeFile, contentType, fileName })
      this.isLoading[typeDocument][typeFile] = false
    }
  }
}
</script>
